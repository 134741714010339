<template>
    <div @scroll="scrollList" id="scrollBox" class="scrollBoxName" @touchstart="startScroll($event)" @touchmove="moveScroll($event)"
         @touchend="endScroll($event)">
        <div class="scrollLoading">
            <div class="scrollLoadingBox" :style="allLoadingStyle">
                <img :src="loadingImg" class="scrollIcon">
            </div>
            <!--<span>加载中,请稍候...</span>-->
        </div>
        <slot name="scrollBox"></slot>
        <div class="scrollLoading" v-if="loadType=='bottom'">
            <img :src="loadingImg">
            <!--<span>加载中,请稍候...</span>-->
        </div>
        <div class="scrollLoading" v-if="loadType=='none'">
            <span>没有更多数据了</span>
        </div>
    </div>
</template>

<script>
    import scrollLoading from './ScrollLoading'

    export default {
        name: "ScrollBox",
        props: {
            // //加载中出现位置(top/bottom/null) 通过参数改变使加载图标显示隐藏
            loadType: {
                type: String,
                default: ""
            },
            // loading背景颜色
            loadingBackground: {
                type: String,
                default: ""
            }
        },
        computed: {
            allLoadingStyle() {
                return Object.assign({
                    background: this.loadingBackground
                }, this.loadingStyle)
            }
        },
        watch: {
            'loadType': function () {
                if (!this.loadType || ['none'].includes(this.loadType)) {
                    this.loadingStyle = {
                        height: '0px'
                    }
                } else if (this.loadType == 'top') {
                    let dom = document.getElementsByClassName('scrollIcon')[0];
                    if (dom) {
                        let maxHeight = dom.height;
                        this.loadingStyle = {
                            height: maxHeight + 'px'
                        };
                    }
                }
            },
        },
        data() {
            return {
                value: {},
                touchTop: 0,
                touchScrollTop: 0,
                loadingStyle: {},
                loadingImg: scrollLoading,
                scrollEventTarget: null
            }
        },
        created() {
            this.scrollEventTarget = this.getScrollEventTarget(document.getElementById('scrollBox'));
            this.loadingStyle = {
                height: '0px'
            }
        },
        methods: {
            scrollList() {
                let clientHeight = document.getElementById('scrollBox').clientHeight;
                let scrollTop = document.getElementById('scrollBox').scrollTop;
                let scrollHeight = document.getElementById('scrollBox').scrollHeight;
                // if (!scrollTop) {
                //     this.$emit('onTop');
                // }
                if ((clientHeight + scrollTop) >= (scrollHeight - 100)) {
                    this.$emit('on-bottom')
                }
            },
            startScroll(e) {
                this.touchTop = e.touches[0].clientY;
                this.touchScrollTop = document.getElementById('scrollBox').scrollTop;
            },
            moveScroll(e) {
                let dom = document.getElementsByClassName('scrollIcon')[0];
                let maxHeight = dom ? dom.height : 0;
                if (e.touches[0].clientY > this.touchTop && maxHeight >= (e.touches[0].clientY - this.touchTop) && !this.touchScrollTop) {
                    this.loadingStyle = {
                        height: e.touches[0].clientY - this.touchTop + 'px'
                    }
                }
            },
            endScroll(e) {
              let end = e.changedTouches[0].clientY;
              let maxHeight = document.getElementsByClassName('scrollIcon')[0].height;
              if ((end - this.touchTop > 50) && this.getScrollTop(this.scrollEventTarget) == 0) {
                this.loadingStyle = {
                  height: maxHeight + 'px'
                };
                this.$emit('on-top');
              } else {
                this.loadingStyle = {
                  height: '0px'
                }
              }
            },
            getScrollEventTarget(element) {
                let currentNode = element;
                while (currentNode && currentNode.tagName !== 'HTML' &&
                currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
                    let overflowY = document.defaultView.getComputedStyle(currentNode).overflowY;
                    if (overflowY === 'scroll' || overflowY === 'auto') {
                        return currentNode;
                    }
                    currentNode = currentNode.parentNode;
                }
                return window;
            },
            getScrollTop(element) {
                if (element === window) {
                    return Math.max(window.pageYOffset || 0, document.documentElement.scrollTop);
                } else {
                    return element.scrollTop;
                }
            },
        }
    }
</script>

<style lang="scss">
    @import "./iScrollBox";
</style>
